import consumer from "./consumer"

document.addEventListener('turbolinks:load', () => {
const element = document.getElementById('account_id');
const account_id = element.getAttribute('data-account-id');
if (account_id != null) {
consumer.subscriptions.create({channel: "StatsChannel", account_id: account_id}, {
  connected() {
    // Called when the subscription is ready for use on the server
        console.log("Connected to stats_channel_" + account_id);

  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    //console.log(data.todays_revenue);
    $('#customers').html(data.todays_customers);
    $('#revenue').html(data.todays_revenue);
    document.getElementById('cha-ching').play();

  }
});
}
})